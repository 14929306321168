// Classes
import { ExcelBook }        from '@/Classes/Files/xlsx/ExcelBook'
import { ExcelSheet }       from '@/Classes/Files/xlsx/ExcelSheet'
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { ActionItem }       from '@/Classes/Records/ActionItem'
import { RecordHelper }     from '@/Classes/Records/RecordHelper'
import { AlgorithmTools }   from '@/Classes/Static/AlgorithmTools'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { PrimitiveTools }   from '@/Classes/Static/PrimitiveTools'

// Constants
import { AppValues }   from '@/Constants/Global/AppValues'
import { Breakpoints } from '@/Constants/Global/Breakpoints'
import { Component }   from '@/Constants/Global/Component'

// Dependencies
import Axios, { AxiosResponse } from 'axios'
import VueMixins                from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Component Extend
const DataTable = VueMixins(MixinBase, MixinComponent, MixinResponsive).extend({
	name: 'DataTable',

	props: {
		bordered: { type: Boolean, default: false },
		condensed: Boolean,
		fontsize: String,
		fixed: { type: Boolean, default: false },
		forceNewButtonEnabled: { type: Boolean, default: false },
		itemsPerPage: { type: Number, default: DataTableConstants.DefaultValues.ItemsPerPage },
		newButtonText: { type: String, default: 'Nuevo Registro' },
		newButtonVariant: { type: String, default: 'green' },
		numButtons: { type: Number, default: DataTableConstants.DefaultValues.NumPaginationButtons },
		show: Boolean,
		showNewButton: { type: Boolean, default: true },
	},

	data: function() {
		return {
			// Propiedades Principales del Componente.
			actions: [],
			currentRow: undefined,
			currentPage: 1,
			items: [],
			itemsProvider: undefined,
			fields: [],
			fieldsRemoved: [],
			search: { input: '', result: undefined },
			selectable: false,
			sortBy: '',
			sortDesc: false,
			sortDirection: 'asc',
			totalRows: 1,

			// Constantes
			constants: {
				FetchingItemsFromServer: 'Cargando registros desde el servidor...',
				NoItemsMatchSearchCriteria: `Ningun resultado coincide con el criterio de busqueda '%s'`,
				NoItemsToDisplay: 'No se encuentran registros para mostrar',
				RefreshingItems: 'Actualizando Registros...'
			},

			// Permisos Activos.
			permissions: {
				NEW_BUTTON: null
			},

			// Propiedad de Estados.
			states: {
				emptyText: undefined,
				exportDataAsAsync: false,
				exportDataFilterType: AppValues.DataTableExportDataFilterType.PERIOD,
				exportFieldNamesToExclude: ['actions'],
				exportFileFormatSelected: undefined,
				filterBy: '',
				isFetching: false,
				isLocalSearch: true,
				isSearchCurrentlyActive: false,
				isUsingCustomProvider: false,
				maxHeight: undefined,
				preventDefaultStacked: false,
				rowEventDisabled: undefined,
				searchBy: '',
				searchInputDisabled: true,
				showControls: true,
				showExportButtons: true,
				showExportAsCSVButton: true,
				showExportAsJSONButton: true,
				showExportAsXLSXButton: true,
				showExportDataSelection: false,
				showPagination: true,
				showRefreshButton: true,
				showSearchBar: true,
				stacked: false,
				stackedDetailsItemFlags: [] as Array<Component.VueComponents.Global.DataTable.ParsedFlagsObject>,
				xlsxWorkSheetName: 'Sheet 1'
			}
		}
	},

	created: function() {
		this.resetEmptyText()
	},

	mounted: function() {
		this.updateNumRows(this.items.length)
	},

	computed: {
		_getFieldsNames: function(): Array<any> {
			if (this.fields.length > 0) {
				return this.fields.map((f: any) => {
					return f.key
				})
			}
			return []
		},

		_getSelectedExportTypeLabel: function(): string {
			switch (this.states.exportFileFormatSelected) {
				case 'CSV' : return 'CSV (.csv)'
				case 'JSON': return 'JSON (.json)'
				case 'XLSX': return 'Excel (.xlsx)'
				default: return ''
			}
		},

		_getExportDataChoices: function(): Array<{ text: string, value: number }> {
			switch (this.states.exportDataFilterType) {
				case AppValues.DataTableExportDataFilterType.PERIOD:
					return [
						{ text: AppValues.PeriodsChoiceFriendlyTextList.CURRENT_MONTH, value: AppValues.PeriodsChoiceList.CURRENT_MONTH },
						{ text: AppValues.PeriodsChoiceFriendlyTextList.LAST_15_DAYS, value: AppValues.PeriodsChoiceList.LAST_15_DAYS },
						{ text: AppValues.PeriodsChoiceFriendlyTextList.LAST_30_DAYS, value: AppValues.PeriodsChoiceList.LAST_30_DAYS },
						{ text: AppValues.PeriodsChoiceFriendlyTextList.LAST_12_MONTHS, value: AppValues.PeriodsChoiceList.LAST_12_MONTHS },
						{ text: AppValues.PeriodsChoiceFriendlyTextList.CURRENT_YEAR, value: AppValues.PeriodsChoiceList.CURRENT_YEAR },
					]
				case AppValues.DataTableExportDataFilterType.QUANTITY:
					return [
						{ text: AppValues.QuantityChoiceFriendlyTextList.LAST_10_ITEMS, value: AppValues.QuantityChoiceList.LAST_10_ITEMS },
						{ text: AppValues.QuantityChoiceFriendlyTextList.LAST_20_ITEMS, value: AppValues.QuantityChoiceList.LAST_20_ITEMS },
						{ text: AppValues.QuantityChoiceFriendlyTextList.LAST_30_ITEMS, value: AppValues.QuantityChoiceList.LAST_30_ITEMS },
						{ text: AppValues.QuantityChoiceFriendlyTextList.LAST_50_ITEMS, value: AppValues.QuantityChoiceList.LAST_50_ITEMS },
						{ text: AppValues.QuantityChoiceFriendlyTextList.ALL_ITEMS, value: AppValues.QuantityChoiceList.ALL_ITEMS },
					]
			}
		},

		_getExportDataChoicesTitle: function(): string {
			switch (this.states.exportDataFilterType) {
				case AppValues.DataTableExportDataFilterType.PERIOD:   return 'Periodo'
				case AppValues.DataTableExportDataFilterType.QUANTITY: return 'Cantidad'
			}
		},

		_isStacked: function(): boolean {
			return this.states.stacked as boolean
		},

		_pagination: function(): any {
			return this.$refs.pagination || { localNumberOfPages: 1 }
		},

		_sortOptions: function(): Array<{ text: string, value: any }> {
			// Listado completo para el 'Select' de Filtro.
			const options: Array<{ text: string, value: any }> = []

			// Opciones provenientes de 'fields'.
			if (this.fields.length > 0) this.fields
				.filter((f: any) => f.sortable)
				.map((f: any) => options.push({ text: f.label, value: f.key }) 
			)

			// Opciones provenientes de 'stackedDetailsItemFlags'.
			if (this._isStacked) this.states.stackedDetailsItemFlags.forEach((x) => {
				options.push({ text: x.text, value: x.flagKey })
			})

			return options?.sort((a, b) => a.text > b.text ? 1 : -1)
		}
	},

	methods: {
		/* <================|===============================|=================> */
		/* <================| PRIVATE DECLARATION FUNCTIONS |=================> */
		/* <================|===============================|=================> */

		_applyCustomCellClasses: function(field: any, value: any) {
			if (this._isStacked) return ''
			let classes = Array.isArray(value) ? 'content-select' : 'content '
			if (field?.align?.item) classes += `align-${ field?.align?.item } `
			return classes.trim()
		},

		_applyCustomCellContentStyle: function(cell: any) {
			if (typeof cell !== 'object') return ''
			return cell.style
		},

		_applyCustomHeadClasses: function(field: any) {
			let classes = ''
			if (field?.align?.field) classes += `text-${ field?.align?.field } `
			return classes.trim()
		},

		_applyCustomHeadContentStyle: function(field: any) {
			let style = ''
			if (field?.align?.item) style += `text-${ field?.align?.item } `
			return style.trim()
		},

		_bindFieldActionPermissions: function() {
			if (this.actions.length > 0 && this.items.length > 0) {
				for (const actionField of this.actions) {
					const item = this.items[0][actionField]
					for (const action of item) {
						this.setPermission(action.permission, null)
					}
				}
				this.$emit('onDTFieldActionPermissionsBound')
			}
		},

		_colorKeyToColorVar: function(key: string) {
			if (key !== undefined) {
				switch (key) {
					case 'TealDarker':
						return 'teal-darker'
					case 'TealDark':
						return 'teal-dark'
					default:
						return key.toLowerCase()
				}
			}
		},

		_getActionCellContentClass: function(type: string) {
			return `dt-action-cell-${ type } ${ this._isStacked ? 'dt-stacked' : '' }`.trim()
		},

		_getActionCellsClass: function() {
			return `dt-action-cell ${ this._isStacked ? 'dt-stacked' : '' }`.trim()
		},

		_getClass: function(name: string) {
			// Strings con todas las clases CSS según el caso.
			let cls = ''

			// Validaciones por Clase.
			switch (name) {
				case 'dt-tbody':
					if (this._isStacked) return `${ name } dt-stacked`
					return name
				case 'dt-tbody-tr':
					cls += 'dt-tbody-tr '
					if (this.condensed) cls += 'dt-condensed '
					if (this._isStacked) cls += 'dt-stacked '
					if (!this.selectable || this._isEmpty()) cls += 'not-selectable '
					return cls.trim()
				default:
					return ''
			}
		},

		_getDataTableClasses: function() {
			return { ...this.getResponsiveClass, ['dt-stacked']: this._isStacked }
		},

		_getDetailsProps: function(objectDetails: any) {
			// Array que contiene la Definición para el apartado 'Details'.
			const details: Array<{ key: string, value: any }> = []

			// Limpiar las 'Propiedades' de cualquier posible 'Flag'.
			Object.keys(objectDetails).forEach((key) => {
				const keyFlags = key.split('|')
				const hasAnyFlag = keyFlags.length > 1
				const newKey = hasAnyFlag ? keyFlags.shift() : key
				details.push({ key: newKey, value: objectDetails[key] })
			})

			return details
		},

		_getEmptyText: function() {
			return this.states.emptyText
		},

		_getFetchText: function() {
			return this.constants.FetchingItemsFromServer
		},

		_getItems: function() {
			if (this.search.input !== '' && this.search.result) {
				this.updateNumRows(this.search.result.length)
				return this.search.result
			}
			else {
				if (typeof this.itemsProvider === 'function') return this.itemsProvider
				this.updateNumRows(this.items.length)
				return this.items
			}
		},

		_getIterableItems: async function() {
			if (typeof this.itemsProvider === 'function') return await this.itemsProvider()
			return this.items
		},

		_getMaxHeight: function() {
			const { maxHeight } = this.states
			if (maxHeight) {
				if (typeof maxHeight === 'number') {
					return `max-height: ${ maxHeight }px;`
				}
				else {
					return `max-height: ${ maxHeight };`
				}
			}
		},

		_getSearchInputDisabled: function() {
			return this.states.searchInputDisabled
		},

		_getStackedClass: function() {
			return this._isStacked ? 'dt-stacked' : ''
		},

		_getTableClasses() {
			const fontsize = this.$props.fontsize ? ` dt-fontsize-${ this.$props.fontsize }` : ''
			const overflowHiddenOnEmptyData = this.fields.length === 0 && this.items.length === 0 ? ' dt-table-empty' : ''
			return 'dt-table' + fontsize + overflowHiddenOnEmptyData
		},

		_isEmpty: function() {
			return this.fields.length === 0 && this.items.length === 0
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			// Controlar la Visibilidad de los elementos según el Breakpoint.
			if (!this.states.preventDefaultStacked) {
				const stacked = breakpoint <= Breakpoints.Large
				this.setStates<DataTableRef['states']>({ stacked })
				this.$emit('onDTStackedUpdated', stacked)
			}
		},

		_parseCellContent: function(cell: any) {
			if (typeof cell !== 'object') return cell
			return cell.value
		},

		_parseDetailsItemFlags: async function() {
			// Ejecutar método unicamente si 'stackedDetailsItemFlags' esta vacio.
			const { stacked, stackedDetailsItemFlags } = this.states
			if (!stacked || stackedDetailsItemFlags.length > 0) return

			// Array que contiene la Definición para el apartado 'Details'.
			const items = await this._getIterableItems()
			const objectDetails = items[0]?._details

			// Analizar la 'construcción' de las 'keys' en busqueda de 'Flags'.
			Object.keys(objectDetails).forEach((key) => {
				// Objeto con todas las posibles 'Flags' de la 'Propiedad o Columna'.
				const flags = RecordHelper.parseFlags(key)
				if (flags.flagKey !== '') stackedDetailsItemFlags.push({ ...flags })
			})
		},

		_parseReadOnlyItemOptions: function(options: Array<string>) {
			return options.map((x) => ({ value: x, text: x, disabled: true })).sort((a, b) => a.text > b.text ? 1 : -1)
		},

		_parseTemplateCell: function(key: string) {
			return `cell(${key})`
		},

		_parseTemplateHead: function(key: string) {
			return `head(${key})`
		},

		_parseValueForDataExports: function(exportType: any, fieldKey: string, value: any) {
			switch (exportType) {
				case 'JSON':
					if ((!!value) && (value.constructor === Object)) return value?.text || value?.value
					if (value instanceof ActionItem) return value.getText()
					return value
				case 'XLSX':
					if (Array.isArray(value)) return JSON.stringify(value).replace('[', '').replace(']', '')
					if ((!!value) && (value.constructor === Object)) return value?.text || value?.value
					if (value instanceof ActionItem) return value.getText()
					return value
				default:
					return value
			}
		},

		_resolveActionButtonStyle: function(item: any) {
			const variant = item.variant || 'gray'
			const value = this._colorKeyToColorVar(variant)
			const h = `var(--hsl-${ value }-h)`, s = `var(--hsl-${ value }-s)`, l = `var(--hsl-${ value }-l)`
			return `
				background-color: hsla(${ h }, ${ s }, ${ l }, .75);
				border-color: var(--${ value });
				border-width: 2px;
			`
		},

		_showDetailsButton: function({ field, item }: any) {
			return this.states.stacked && (item._details && field?.showDetailsButton === true)
		},

		_sortCompare: function(a: any, b: any, key: string, sortDesc: boolean, formatter: any, compareOptions: object, compareLocale: string) {
			// Celdas de las filas a comparar.
			const field = this.fields.find((x: any) => x.key === key)
			const rows: { [key: string]: any } = { a, b }
			const cell: any = { a: rows.a[key], b: rows.b[key] }

			// Solo realizar las comparaciones si las celdas son diferentes a 'undefined'.
			if (cell?.a && cell?.b) {
				// Comparación personalizado para la columna 'actions'.
				if (field?.IS_ACTION) {
					if ((cell.a.length === 1 && cell.b.length === 1)) {
						if (cell.a[0]?.sort && cell.b[0]?.sort) {
							// Propiedades que serán evaluadas por la comparación.
							const values: any = { a: '', b: '' }

							// Evaluación de la propiedad 'sort' de ambas celdas.
							for (const cellKey of ['a', 'b']) {
								for (const sorting of cell[cellKey][0].sort) {
									switch (sorting.from) {
										case '$row':
											values[cellKey] += rows[cellKey][sorting.key]
											break
										case '$self':
											values[cellKey] += cell[cellKey][0][sorting.key]
											break
									}
								}
							}
							return this._sortCompareValues(values.a, values.b, compareLocale, compareOptions)
						}
					}
				}
				return this._sortCompareValues(cell.a, cell.b, compareLocale, compareOptions)
			}
		},

		_sortCompareValues: function(a: (number | Date | string), b: (number | Date | string), compareLocale: any, compareOptions: any) {
			if ((typeof a === 'number' && typeof b === 'number') || (a instanceof Date && b instanceof Date)) {
				return a < b ? -1 : a > b ? 1 : 0
			}
			else {
				const aStr = a.toString()
				const bStr = b.toString()

				// Comprobar que los valores que son strings, puedan ser convertidos a Date
				// para realizar una correcta comparación de las fechas.
				if (PrimitiveTools.Dates.isValidDateString(aStr) && PrimitiveTools.Dates.isValidDateString(bStr)) {

					// El constructor Date, espera una fecha con formato 'MM-dd-YYYY'.
					const aSplit = aStr.split(' ')
					const bSplit = bStr.split(' ')

					// Invertir los días con los meses antes de enviarlos al constructor.
					const aDateSplit = aSplit[0].split('-')
					let aFormatted = `${ aDateSplit[1] }-${ aDateSplit[0] }-${ aDateSplit[2] }`

					const bDateSplit = bSplit[0].split('-')
					let bFormatted = `${ bDateSplit[1] }-${ bDateSplit[0] }-${ bDateSplit[2] }`

					// Concatenar la hora si es que se incluia en el string.
					if (aSplit.length === 2 && bSplit.length === 2) {
						aFormatted += ` ${ aSplit[1] }`
						bFormatted += ` ${ bSplit[1] }`
					}
					
					// Crear las instancias de 'Date' con las fechas modificadas.
					const aIsDate = new Date(aFormatted)
					const bIsDate = new Date(bFormatted)

					// Son efectivamente valores validos de fechas?
					if (aIsDate.toString() !== 'Invalid Date' && bIsDate.toString() !== 'Invalid Date') {
						const newA = aIsDate.getTime()
						const newB = bIsDate.getTime()
						return newA < newB ? -1 : newA > newB ? 1 : 0
					}

					return aStr.localeCompare(bStr, compareLocale, compareOptions)
				}
				return aStr.localeCompare(bStr, compareLocale, compareOptions)
			}
		},

		_updateTotalPages: function(n: number) {
			this._pagination.localNumberOfPages = n
		},

		/* <=================|==============================|=================> */
		/* <=================| PUBLIC DECLARATION FUNCTIONS |=================> */
		/* <=================|==============================|=================> */

		addRow: function(item: any) {
			this.items.push(item)
			this.updateNumRows(this.items.length)
		},

		clearAll: function() {
			this.clearCurrentRow()
			this.clearData()
			this.clearSortBy()
			this.clearPagination()
		},

		clearCurrentRow: function() {
			this.currentRow = undefined
			const table: any = this.$refs?.table
			table?.clearSelected()
		},

		clearData: function() {
			this.fields = []
			this.items = []
			this.itemsProvider = undefined
			this.setStates<DataTableRef['states']>({ stackedDetailsItemFlags: [], isUsingCustomProvider: false })
			this.clearCurrentRow()
		},

		clearPagination: function() {
			this._updateTotalPages(1)
			this.currentPage = 1
		},

		clearSortBy: function() {
			this.sortBy = ''
			this.states.filterBy = ''
			this.search.input = ''
			this.search.result = undefined
		},

		doAsyncFetch: async function(p: Promise<any>) {
			try {
				this.setStates<DataTableRef['states']>({ isFetching: true })
				const response = await p
				
				if (response) {
					this.setStates<DataTableRef['states']>({ isFetching: false })
					return response
				}
			}
			catch (err) {
				this.resetFetchState()
				return null
			}
		},

		doInputSearch: async function(endpoint: string, dataParser: any, params: any, responseHandler?: (response: AxiosResponse<any>) => { data: Array<any>, totalPages: number }) {
			const { input } = this.search
			const { filterBy } = this.states

			// 'Tipo' de Busqueda.
			const { isLocalSearch } = this.states

			// Emitir evento cuando se elimine el criterio de busqueda.
			if (!isLocalSearch && input === '') {
				this.setStates<DataTableRef['states']>({ isSearchCurrentlyActive: false })
				this.$emit('onDTPaginationChanged', 1)
				this.setPaginationPage(1)
				this.search.result = undefined
				return
			}
			
			// Asegurar que existe un Input y un Filtro especificado.
			if (input !== '' && filterBy !== '') {
				let preventSearchOnInputKeyPress = true
				let items = [], totalPages = null as number
				const result = []

				// Busqueda de Registros Locales.
				if (isLocalSearch) {
					items = (await this._getIterableItems()) as Array<any>
					preventSearchOnInputKeyPress = false
				}

				// Busqueda de Registros desde Endpoint.
				if (!isLocalSearch && (endpoint && dataParser && params !== undefined)) {
					const headers = AxiosManager.AuthenticationHeader
					const response = await Axios.get(endpoint, { headers, params })
					let responseBody = response.data.body

					// Si los 'registros' se encuentran en una propiedad diferente a la por defecto,
					// obtenerlos a traves del 'responseHandler'.
					if (responseHandler) {
						const customResponse = responseHandler(response)
						responseBody = customResponse.data
						totalPages   = customResponse.totalPages
					}

					const parsedData = dataParser(responseBody)
					items = parsedData?.items as Array<any>
					preventSearchOnInputKeyPress = false
				}

				// Impedir continuar con Proceso de Datos (en caso particular).
				if (preventSearchOnInputKeyPress) return

				// Buscar coincidencias en los Registros.
				for (const item of items) {
					if (filterBy === 'actions') {
						if (item[filterBy].length > 0) {
							const inputText = input.toLowerCase()
							const elementText = item[filterBy][0].text.toString().toLowerCase()
							if (AlgorithmTools.KMPSearch(inputText, elementText) !== -1) result.push(item)
						}
					}
					else if (filterBy === 'rut') {
						const inputText = input.toLowerCase().replace(/\./g, '')
						const itemText = item[filterBy].toString().toLowerCase().replace(/\./g, '')
						if (AlgorithmTools.KMPSearch(inputText, itemText) !== -1) result.push(item)
					}
					else if (item[filterBy] !== null) {
						const inputText = input.toLowerCase()
						const itemText = item[filterBy].toString().toLowerCase()
						if (AlgorithmTools.KMPSearch(inputText, itemText) !== -1) result.push(item)
					}
				}

				// Registros obtenidos desde un 'Endpoint' externo.
				if (!isLocalSearch && (endpoint && dataParser && params !== undefined)) {
					if (responseHandler && totalPages !== null) {
						this.updateElementsAndPagination(totalPages, null, result, null)
						return
					}
				}
				
				this.search.result = result
				this.setStates<DataTableRef['states']>({ emptyText: this.constants.NoItemsMatchSearchCriteria.replace('%s', input) })
			}

			// Vaciar los Resultados de Busqueda al borrar el String de filtro.
			else {
				this.search.result = undefined
				this.setStates<DataTableRef['states']>({ emptyText: this.constants.NoItemsToDisplay })
			}
		},

		exportDataAsJSON: async function(data?: Array<any>) {
			// Validación de la Data antes de la exportación.
			const _items: Array<any> = data || (await this._getIterableItems())

			// Comprobar que se tienen registros para exportar.
			if (this.fields.length === 0 && _items.length === 0) {
				return this.showToast('Exportar Archivo .JSON', 'No existe Data para exportar como archivo .JSON', 'danger')
			}

			// Configuraciones según cada caso.
			const { exportFieldNamesToExclude } = this.states

			// Generar las Columnas.
			const columnKeys = this.fields
			.filter((x: any) => !(exportFieldNamesToExclude.find((c: string) => c === x.key)))
			.map((x: any) => ({ key: x.key, label: x.label }))

			// Generar el Array con la data para exportar.
			const items: Array<any> = []
			for (const item of _items) {
				const itemObject: any = {}
				for (const key of columnKeys) {
					const columnKey = key.key, columnLabel = key.label
					const exclude = exportFieldNamesToExclude.find((c: string) => c === columnKey)
					if (columnKey !== exclude) {
						const itemValue = item[columnKey]
						const value = Array.isArray(itemValue) && itemValue.length === 1 ? itemValue[0] : itemValue
						itemObject[columnLabel] = this._parseValueForDataExports('JSON', columnKey, value)
					}
				}
				items.push(itemObject)
			}

			// Generar la Data codificada para ser guardada como archivo .JSON
			const text = JSON.stringify(items)
			const dataURI = `data:application/json;charset=utf-8,${ encodeURIComponent(text) }`
			const fileName = new Date().getTime().toString()

			// Crear un elemento temporal <a> para descargar el archivo.
			const aElement = document.createElement('a')
			aElement.setAttribute('href', dataURI)
			aElement.setAttribute('download', `${ fileName }.json`)
			aElement.click()
		},

		exportDataAsXLSX: async function(asCSV: boolean, data?: Array<any>) {
			// Validación de la Data antes de la exportación.
			const _items: Array<any> = data || (await this._getIterableItems())
			const fileExtension = asCSV ? 'CSV' : 'XLSX'

			// Comprobar que se tienen registros para exportar.
			if (this.fields.length === 0 && _items.length === 0) {
				return this.showToast(`Exportar Archivo .${ fileExtension }`, `No existe Data para exportar como archivo .${ fileExtension }`, 'danger')
			}

			// Configuraciones según cada caso.
			const { exportFieldNamesToExclude, xlsxWorkSheetName } = this.states

			// Referencias.
			const excelBook  = new ExcelBook()
			const workBook   = excelBook.getWorkBook()
			const excelSheet = new ExcelSheet(workBook, xlsxWorkSheetName)
			const workSheet  = excelSheet.getWorkSheet()

			// Generar las Columnas.
			const columnKeys: Array<string> = []
			const columns: Array<any> = this.fields
			.filter((x: any) => !(exportFieldNamesToExclude.find((c: string) => c === x.key)))
			.map((x: any) => { columnKeys.push(x.key); return ({ key: x.key, header: x.label, width: 20 }) })

			// Definir las Columnas (Headers) del documento XLSX.
			excelSheet.setColumns(columns).setRowHeight(workSheet.getRow(1), 20)
			
			// Aplicar un estilo al Header.
			workSheet.getRow(1).eachCell((cell) => {
				excelSheet
					.setCellAlignment(cell, 'center', 'middle')
					.setCellFontBold(cell)
					.setCellFontColor(cell, 'FFFFFFFF')
					.setCellSolidFill(cell, '4C78B3')
				;
			})

			// Agregar la Información del resto de filas.
			let currentRowIndex = 2
			for (const item of _items) {
				let currentCellIndex = 1
				const row = workSheet.getRow(currentRowIndex++)
				excelSheet.setRowHeight(row, 20)

				for (const columnKey of columnKeys) {
					const cell = row.getCell(currentCellIndex++)
					const itemColumn = item[columnKey]
					const value = Array.isArray(itemColumn) && itemColumn.length === 1 ? itemColumn[0] : itemColumn
					cell.value = this._parseValueForDataExports('XLSX', columnKey, value)
					excelSheet.setCellAlignment(cell, null, 'middle')
				}
			}

			// Crear el archivo XLSX (o CSV).
			const fileName = new Date().getTime().toString();
			(asCSV) ? await excelBook.saveFileAsCSV(fileName) : await excelBook.saveFileAsXLSX(fileName)
		},

		getCurrentRow: function(): any {
			return this.currentRow
		},

		handleExportDataType: function(format: string, data: any) {
			switch (format) {
				case 'CSV':
					this.exportDataAsXLSX(true, data)
					break
				case 'JSON':
					this.exportDataAsJSON(data)
					break
				case 'XLSX':
					this.exportDataAsXLSX(false, data)
					break
			}
		},

		resetEmptyText: function() {
			this.setStates<DataTableRef['states']>({ emptyText: this.constants.NoItemsToDisplay })
		},

		resetFetchState: function() {
			this.setStates<DataTableRef['states']>({ emptyText: this.constants.NoItemsToDisplay, isFetching: false })
		},

		setActions: function(actions: string[]) {
			this.actions = actions
		},

		setElements: function(fields: Array<any>, items: Array<any>, actions?: Array<string>) {
			if (fields) this.setFields(fields)
			if (items) this.setItems(items)
			if (actions) this.setActions(actions)
			if (actions) this._bindFieldActionPermissions()
		},

		setFetchingState: function() {
			this.setStates<DataTableRef['states']>({ isFetching: true })
		},

		setFields: function(fields: Array<any>) {
			this.fields = fields.filter((x) => x.key !== '_showDetails' && x.key !== '_details')
		},

		setFieldVisible: function(fieldKey: string, visible: boolean) {
			if (!visible) {
				// Encontrar el Campo, almacenarlo temporalmente y removerlo del Array de Campos.
				const fieldToRemove = { index: <number> undefined, field: <any> undefined }
				
				for (let i = 0; i < this.fields.length; i++) {
					const field = this.fields[i]
					if (field.key === fieldKey) {
						fieldToRemove.index = i
						fieldToRemove.field = field
						break
					}
				}

				if (fieldToRemove.index !== undefined) {
					this.fields.splice(fieldToRemove.index, 1)
					this.fieldsRemoved.push(fieldToRemove.field)
				}
			}
		},

		setItems: function(items: Array<any>) {
			this.items = items !== undefined ? items : []
			this.setStates<DataTableRef['states']>({ emptyText: this.constants.NoItemsToDisplay, isFetching: false })
			this.updateNumRows(this.items.length)
		},

		setItemsProvider: function(provider: (ctx: any, callback?: any) => Promise<Array<any>>) {
			this.itemsProvider = provider
			this.setStates<DataTableRef['states']>({ emptyText: this.constants.NoItemsToDisplay, isFetching: false, isUsingCustomProvider: true })
		},

		setMaxHeight: function(m: number | string) {
			this.setStates<DataTableRef['states']>({ maxHeight: m })
		},

		setPaginationPage: function(page: number) {
			this._pagination.currentPage = page
			this.onDTPaginationChanged(page)
		},

		setPermission: function(key: string, value: boolean) {
			(<any> this.permissions)[key] = value
		},

		setSelectable: function(state: boolean) {
			this.selectable = state
		},

		sortOrder: function(fieldKey: string, direction = 'asc') {
			this.sortBy = fieldKey
			this.sortDesc = direction === 'desc'
			this.sortDirection = direction

			// Asignar las Propiedades para la funcionalidad del 'Buscar'.
			const fromFieldsArray  = this.fields.find((x) => x.key === fieldKey)
			const fromStackedArray = fromFieldsArray || this.states.stackedDetailsItemFlags.find((x) => x.flagKey === fieldKey)
			this.setStates<DataTableRef['states']>({ filterBy: fieldKey, searchBy: fromFieldsArray?.searchKey || fromStackedArray?.flagSearchKey || fieldKey, searchInputDisabled: fieldKey === '' })
		},

		updateElementsAndPagination: function(totalPages: number, fields: Array<any>, items: Array<any>, actions?: Array<string>) {
			this._updateTotalPages(totalPages || 1)
			if (fields) this.setFields(fields)
			this.setItemsProvider(async () => items)
			if (actions) this.setActions(actions)
			if (actions) this._bindFieldActionPermissions()
		},

		updateNumRows: function(numRows: number) {
			this.totalRows = numRows
		},

		updateRow: function(item: any, key = '_id') {
			for (let i = 0; i < this.items.length; i++) {
				const _item = this.items[i]
				if (_item[key] === item[key]) {
					this.items.splice(i, 1, item)
					this.doInputSearch(null, null, null)
					break
				}
			}
		},

		/* <=================|=============================|==================> */
		/* <=================| EVENT DECLARATION FUNCTIONS |==================> */
		/* <=================|=============================|==================> */

		onCheckSearchInput: function(fieldKey: any) {
			const fromFieldsArray  = this.fields.find((x) => x.key === fieldKey)
			const fromStackedArray = fromFieldsArray || this.states.stackedDetailsItemFlags.find((x) => x.flagKey === fieldKey)
			this.setStates<DataTableRef['states']>({ searchBy: fromFieldsArray?.searchKey || fromStackedArray?.flagSearchKey || fieldKey, searchInputDisabled: fieldKey === '' })
		},

		onDTButtonClick: function(key: string, row: any, button: any) {
			DevelopmentTools.printWarn('[DataTable]:onDTButtonClick event triggered')
			this.$emit('onDTButtonClick', key, row, button)
		},

		onDTExportDataClick: function(filterValue: AppValues.PeriodsChoiceList | AppValues.QuantityChoiceList) {
			DevelopmentTools.printWarn('[DataTable]:onDTExportDataClick event triggered')
			const { exportDataFilterType, exportFileFormatSelected } = this.states
			this.$emit('onDTExportDataClick', exportFileFormatSelected, exportDataFilterType, filterValue)
			this.setStates<DataTableRef['states']>({ exportFileFormatSelected: undefined, showExportDataSelection: false })
		},

		onDTExportToFileClick: function(exportType: string) {
			DevelopmentTools.printWarn('[DataTable]:onDTExportToFileClick event triggered')

			// Decargar los Registros actuales mostrados en el componente 'DataTable' si 'exportDataAsAsync' es FALSE.
			const { exportDataAsAsync, exportFileFormatSelected } = this.states
			if (!exportDataAsAsync) {
				switch (exportType) {
					case 'CSV' : return this.exportDataAsXLSX(true)
					case 'JSON': return this.exportDataAsJSON()
					case 'XLSX': return this.exportDataAsXLSX(false)
				}
			}

			// Comprobar si se debe ocultar el 'Popup' de Periodo.
			if (exportType === null || exportFileFormatSelected === exportType) {
				this.setStates<DataTableRef['states']>({ exportFileFormatSelected: undefined, showExportDataSelection: false })
			}

			// De lo contario, simplementa actualizar al nuevo 'Formato'.
			else this.setStates<DataTableRef['states']>({ exportFileFormatSelected: exportType, showExportDataSelection: true })
		},

		onDTNewButtonClick: function() {
			DevelopmentTools.printWarn('[DataTable]:onDTNewButtonClick event triggered')
			this.$emit('onDTNewButtonClick')
		},

		onDTPaginationChanged: function(page: number) {
			// Evento de Paginación según uso de Campo Buscar.
			const { isSearchCurrentlyActive } = this.states
			const eventName = isSearchCurrentlyActive ? 'onDTCurrentSearchPaginationChanged' : 'onDTPaginationChanged'

			// Limpiar Tabla para obtener posibles nuevos registros.
			if (!isSearchCurrentlyActive) this.clearData()
			this.setStates<DataTableRef['states']>({ emptyText: this.constants.RefreshingItems, isFetching: true })

			// Información del Evento axionado.
			DevelopmentTools.printWarn(`[DataTable]:${ eventName } event triggered`)
			this.$emit(eventName, page, { searchKey: this.states.searchBy, searchValue: this.search.input })
		},

		onDTRefreshButtonClick: function() {
			DevelopmentTools.printWarn('[DataTable]:onDTRefreshButtonClick event triggered')
			this.clearData()
			this.setStates<DataTableRef['states']>({ emptyText: this.constants.RefreshingItems, isFetching: true })
			this.$emit('onDTRefreshButtonClick', this._pagination.currentPage || 1)
		},

		onDTSearchButtonClicked: function() {
			DevelopmentTools.printWarn('[DataTable]:onDTSearchButtonClicked event triggered')
			if ((!this.states.isLocalSearch) && this.search.input !== '') this.setStates<DataTableRef['states']>({ isSearchCurrentlyActive: true })
			this.$emit('onDTSearchButtonClicked', { searchKey: this.states.searchBy, searchValue: this.search.input })
		},

		onRowClickHandler: function(clickType: string, item: any, index: number) {
			// Por prioridad, si el valor de la propiedad 'selectable' es FALSE, omitir la ejecución de este evento.
			if (!this.selectable) return

			DevelopmentTools.printWarn(`[DataTable]:onRow${clickType} event triggered`)
			this.currentRow = { item, index }
			this.$emit(`onRow${clickType}`, item, index)
		},

		onRowsSelected: function(rows: Array<any>) {
			// Por prioridad, si el valor de la propiedad 'selectable' es FALSE, omitir la ejecución de este evento.
			if (!this.selectable) return
			
			DevelopmentTools.printWarn(`[DataTable]:onRowsSelected event triggered`)
			if (rows.length === 0) {
				this.clearCurrentRow()
				this.$emit('onRowsUnselected')
			}
		}
	},

	watch: {
		['items']: function(newValue: Array<any>) {
			if (newValue?.length > 0) this._parseDetailsItemFlags()
		},

		['itemsProvider']: function(newValue: any) {
			if (newValue !== undefined) this._parseDetailsItemFlags()
		},

		['search.input']: function() {
			this.doInputSearch(null, null, null)
		}
	}
})

// Exports
export default DataTable
export type DataTableRef = InstanceType<typeof DataTable>